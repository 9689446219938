/**
 * @method GET
 * @description получаем банеры
 * */

import type { IApiImage } from '~/api/shared'

export interface IApiContentBannerProps {
  page: string
  type: string
}

export interface IApiContentBannerItem {
  data: {
    id: string
    type: string
    pages: string[]
    link: string
    openLinkInNewTab: boolean
    showToMembers: boolean
    showToGuests: boolean
    weight: number
    timestampStart: number
    timestampEnd: number
    imageDesktop: IApiImage | null
    imageMobile: IApiImage | null
    video: IApiImage | null
    videoMobile: IApiImage | null
  },
}

export default async function apiContentBanner (payload: IApiContentBannerProps) {
  const endpoint = `/content/v1/ad-banner/${payload.page}/${payload.type}`

  return await useAsyncQuery<IApiContentBannerItem>(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
