<template>
  <div v-if="banner" class="ui-small-baner" data-testid="element_000043">
    <ui-link :link="{ to: banner.link, inNewTab: banner.inNewTab }">
      <img v-if="banner.video === null" class="ui-small-baner__img" :title="banner.title" :alt="banner.alt" :src="banner.src" data-testid="elem_vrp_001056">
      <img v-if="banner.video === null" class="ui-small-baner__img-blur" :title="banner.title" :alt="banner.alt" :src="banner.src" data-testid="elem_vrp_001057">
      <video
        v-if="banner.video"
        ref="video"
        autoplay
        loop
        muted
        playsinline
        class="ui-small-baner__img"
        :poster="banner.src"
        data-testid="elem_vrp_001058"
      >
        <source :src="banner.video.path">
      </video>
    </ui-link>
  </div>
</template>

<script lang="ts" setup>
/**
 * @description when changed template send to QA
 * */

import apiContentBanner from '~/api/content/banner'

const props = defineProps({
  page: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
})

const { width } = useSSRWindowSize()

const smallBannerQuery = await useAsyncData(`small-banner:${props.page}:${props.type}`, async () => {
  return await apiContentBanner({ page: props.page, type: props.type })
}, { dedupe: 'defer', lazy: true })

const unpack = (response: Awaited<ReturnType<typeof apiContentBanner>> | null) => {
  if (response?.data?.id) {
    return response.data
  }
  return null
}

const banner = computed(() => {
  const info = unpack(smallBannerQuery.data.value)
  if (!info) {
    return null
  }

  return {
    title: 'banner',
    src: width.value > 599 ? info.imageDesktop?.path : info.imageMobile?.path,
    video: width.value > 599 ? info.video : info.videoMobile,
    link: info.link,
    inNewTab: info.openLinkInNewTab,
    alt: 'banner',
  }
})
</script>

<style lang="scss">
.ui-small-baner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @include bp-mobile() {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & &__img {
    object-fit: cover;
    width: 100%;
    height: 244rem;
    border-radius: 10rem;
    z-index: 2;
    position: relative;

    @include bp-tablet() {
      height: 111rem;
    }

    @include bp-mobile() {
      border-radius: 0;
      margin: 24rem 0;
      height: 180rem;
      object-fit: cover;
    }
  }

  & &__img-blur {
    display: none;

    @include bp-mobile() {
      display: block;
      position: absolute;
      filter: blur(24rem);
      width: 312rem;
      height: 180rem;
      z-index: 1;
      object-fit: cover;
    }
  }
}
</style>
